<template>
  <Card class="mt-4" style="width: 40vw">
    <template #header>
      <h1 class="display-3 text-center">Créer un compte</h1>
    </template>
    <template #content>
      <form class="p-field" @submit.prevent="onSubmit">
        <div class="flex">
          <div class="p-fluid col-6">
            <label for="firstName">Prénom</label>
            <InputText id="firstName" v-model="firstName" :disabled="pending" />
          </div>
          <div class="p-fluid col-6">
            <label for="lastName">Nom</label>
            <InputText id="lastName" v-model="lastName" :disabled="pending" />
          </div>
        </div>
        <div class="flex">
          <div class="p-fluid col">
            <label for="email">Email</label>
            <InputText id="email" v-model="email" :disabled="pending" />
          </div>
        </div>
        <div class="flex">
          <div class="p-fluid col-6">
            <label for="password">Mot de passe</label>
            <Password
              id="password"
              v-model="password"
              toggleMask
              :disabled="pending"
            />
          </div>
          <div class="p-fluid col-6">
            <label for="passwordConfirm">Confirmation</label>
            <Password
              id="passwordConfirm"
              v-model="passwordConfirm"
              toggleMask
              :disabled="pending"
            />
          </div>
        </div>
        <div class="p-fluid col mt-3">
          <Button type="submit" label="S'enregistrer" :loading="pending" />
        </div>
      </form>
    </template>
    <template #footer></template>
  </Card>
</template>

<script>
import { reactive, toRefs } from "vue";
import useAuth from "../../composables/useAuth";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { projectFunctions } from "@/firebase/config";

export default {
  setup() {
    const toast = useToast();
    const router = useRouter();

    const newUser = reactive({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirm: "",
    });

    const onClear = () => {
      newUser.firstName = "";
      newUser.lastName = "";
      newUser.email = "";
      newUser.password = "";
      newUser.passwordConfirm = "";
    };

    const { register, pending, error } = useAuth();

    const onSubmit = async () => {
      if (newUser.password === newUser.passwordConfirm) {
        const result = await projectFunctions
          .httpsCallable("listUsers")
          .call(null);
        const users = result.data;
        let profile = {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
        };
        await register(
          newUser.email,
          newUser.password,
          profile,
          [],
          users.length ? "moderator" : "admin",
          !!users.length
        );
        await router.replace({ name: "Login" });
        onClear();
        toast.add({
          severity: "success",
          detail: "Nouveau compte créé avec succès !",
          life: 2000,
        });
      }
    };

    return { ...toRefs(newUser), onSubmit, pending, error };
  },
};
</script>

<style lang="scss" scoped></style>
