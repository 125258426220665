import {
  projectAuth,
  authPersistence,
  projectFunctions,
} from "@/firebase/config";
import { ref } from "vue";

export default () => {
  const error = ref(null);
  const pending = ref(null);

  const login = async ({ email, password, remember }) => {
    let storage;
    switch (remember) {
      case true:
        storage = "LOCAL";
        break;
      default:
        storage = "SESSION";
    }
    try {
      pending.value = true;
      await projectAuth.setPersistence(authPersistence[storage]);
      await projectAuth.signInWithEmailAndPassword(email, password);
      const userToken = await projectAuth.currentUser.getIdTokenResult();
      if (
        userToken.claims.role !== "admin" &&
        userToken.claims.role !== "moderator"
      ) {
        error.value = { message: "Accès non autorisé" };
        return await projectAuth.signOut();
      }
      error.value = null;
    } catch (e) {
      error.value = { message: "Adresse email ou mot de passe incorrect" };
    } finally {
      pending.value = null;
    }
  };

  const logout = async () => {
    try {
      await projectAuth.signOut();
    } catch (e) {
      error.value = e.message;
    }
  };

  const register = async (
    email,
    password,
    profile,
    centers,
    role,
    disabled = false
  ) => {
    error.value = null;
    pending.value = true;

    try {
      pending.value = true;
      await projectFunctions.httpsCallable("createUser")({
        email,
        password,
        profile,
        centers,
        role,
        disabled,
      });
    } catch (e) {
      error.value = e.message;
    } finally {
      pending.value = false;
    }
  };

  return { error, pending, login, logout, register };
};
